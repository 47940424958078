<template>
  <section class="app-container store-page">
    <StoreCateTypeTabs v-model="storeCateType" :list="list" v-loading="loading">
      <template #[slotName] v-for="({ slotName }, index) in list">
        <div :key="slotName" class="tabs-wrapper">
          <BaseStoreComponent :form="forms[index]" />
        </div>
      </template>
    </StoreCateTypeTabs>
  </section>
</template>

<script>
import StoreCateTypeTabs from './module/storeCateTypeTabs'
import BaseStoreComponent from './module/baseStoreComponent'
import { list } from './const'
import { getPlatformList as getList } from '@/api/shop/manageApi'
import { mapActions } from 'vuex'

export default {
  components: {
    StoreCateTypeTabs,
    BaseStoreComponent
  },

  data() {
    return {
      storeCateType: '-1', //分类类型
      loading: false,
      forms: [],
      list: []
    }
  },

  created() {
    Promise.all([this.getData(), this.GetWebsiteAreaCodeDict(), this.GetDistributorDict()])
  },

  computed: {
    calcList() {
      return this.list.filter(({ permission }) => permission)
    }
  },

  methods: {
    ...mapActions(['GetWebsiteAreaCodeDict', 'GetDistributorDict']),
    //初始化forms
    initForms() {
      const forms = this.list.map(({ value, label }) => {
        return {
          shopType: value
        }
      })
      this.forms = forms
    },

    //获取顶部的tabs
    async getData() {
      this.loading = true
      try {
        let list = []
        let result = await getList({ page: { pageSize: 0 } })
        list = result?.detail
        if (!list.length) {
          return
        }
        this.list = list.map((item, index) => {
          item.slotName = `${item.name}Slot`
          item.label = item.name
          item.value = item.code
          //将id装换成字符串，防止tab的name值报错
          item.id = item.id + ''
          //给type赋初始值
          if (index == 0) {
            this.storeCateType = item.id
          }
          return item
        })

        //初始化forms
        this.initForms()
      } catch {}
      this.loading = false
    }
  }
}
</script>

<style></style>
